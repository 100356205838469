/*! Tables */

table { background-color: transparent; }
caption { padding-top: 8px; padding-bottom: 8px; color: #777; text-align: left; }
th { text-align: left; }
.table { width: 100%; max-width: 100%; margin-bottom: 20px; font-size: 1.4em; }
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th { padding: 8px; line-height: 1.45; vertical-align: top; border-top: 1px solid #ddd }
.table>thead>tr>th { vertical-align: bottom; border-bottom: 2px solid #ddd }
.table>caption+thead>tr:first-child>td,
.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th { border-top: 0; }
.table>tbody+tbody { border-top: 2px solid #ddd; }
.table .table {	background-color: #fff; }
.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th { padding: 5px }
.table-bordered { border: 1px solid #ddd; }
.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th { border: 1px solid #ddd }
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th { border-bottom-width: 2px }
.table-striped>tbody>tr:nth-of-type(odd) { background-color: #f9f9f9 }
.table-hover>tbody>tr:hover { background-color: #f5f5f5 }
table col[class*=col-] { position: static; display: table-column; float: none }
table td[class*=col-],
table th[class*=col-] { position: static; display: table-cell; float: none }
.table>tbody>tr.active>td,
.table>tbody>tr.active>th,
.table>tbody>tr>td.active,
.table>tbody>tr>th.active,
.table>tfoot>tr.active>td,
.table>tfoot>tr.active>th,
.table>tfoot>tr>td.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>thead>tr.active>th,
.table>thead>tr>td.active,
.table>thead>tr>th.active { background-color: #f5f5f5; }
.table-hover>tbody>tr.active:hover>td,
.table-hover>tbody>tr.active:hover>th,
.table-hover>tbody>tr:hover>.active,
.table-hover>tbody>tr>td.active:hover,
.table-hover>tbody>tr>th.active:hover {	background-color: #e8e8e8 }
.table>tbody>tr.success>td,
.table>tbody>tr.success>th,
.table>tbody>tr>td.success,
.table>tbody>tr>th.success,
.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th,
.table>tfoot>tr>td.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>thead>tr.success>th,
.table>thead>tr>td.success,
.table>thead>tr>th.success { background-color: #dff0d8; }
.table-hover>tbody>tr.success:hover>td,
.table-hover>tbody>tr.success:hover>th,
.table-hover>tbody>tr:hover>.success,
.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover { background-color: #d0e9c6 }
.table>tbody>tr.info>td,
.table>tbody>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>thead>tr>td.info,
.table>thead>tr>th.info { background-color: #d9edf7; }
.table-hover>tbody>tr.info:hover>td,
.table-hover>tbody>tr.info:hover>th,
.table-hover>tbody>tr:hover>.info,
.table-hover>tbody>tr>td.info:hover,
.table-hover>tbody>tr>th.info:hover { background-color: #c4e3f3 }
.table-responsive {	min-height: .01%; overflow-x: auto }

@media screen and (max-width:767px) {
    .table-responsive { width: 100%; margin-bottom: 15px; overflow-y: hidden; -ms-overflow-style: -ms-autohiding-scrollbar; border: 1px solid #ddd; }
    .table-responsive>.table { margin-bottom: 0; }
    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tfoot>tr>td,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>thead>tr>th { white-space: nowrap }
    .table-responsive>.table-bordered {	border: 0 }
    .table-responsive>.table-bordered>tbody>tr>td:first-child,
    .table-responsive>.table-bordered>tbody>tr>th:first-child,
    .table-responsive>.table-bordered>tfoot>tr>td:first-child,
    .table-responsive>.table-bordered>tfoot>tr>th:first-child,
    .table-responsive>.table-bordered>thead>tr>td:first-child,
    .table-responsive>.table-bordered>thead>tr>th:first-child {	border-left: 0 }
    .table-responsive>.table-bordered>tbody>tr>td:last-child,
    .table-responsive>.table-bordered>tbody>tr>th:last-child,
    .table-responsive>.table-bordered>tfoot>tr>td:last-child,
    .table-responsive>.table-bordered>tfoot>tr>th:last-child,
    .table-responsive>.table-bordered>thead>tr>td:last-child,
    .table-responsive>.table-bordered>thead>tr>th:last-child { border-right: 0 }
    .table-responsive>.table-bordered>tbody>tr:last-child>td,
    .table-responsive>.table-bordered>tbody>tr:last-child>th,
    .table-responsive>.table-bordered>tfoot>tr:last-child>td,
    .table-responsive>.table-bordered>tfoot>tr:last-child>th { border-bottom: 0 }
}

.col-date { width: 155px; }
.col-status { width: 120px; }
.col-sticky { width: 105px; }
.col-category { width: 120px; }
.list-table .col-yesno { width: 100px; text-align: center; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; padding: 10px 0; }
.col-updated { width: 160px; }
.col-thumbnail { width: 67px; padding: 0 !important; text-align: center; }
.col-order { text-align: center; width: 100px; }
.col-centre { text-align: center; }

.thumbnail-holder { display: block; overflow: hidden; width: 67px; height: 67px; }
.thumbnail-holder img { height: 67px; width: auto; }

.list-usage-table { width: calc(100% - 40px); border: 1px solid #e3e3e3; border-bottom: none; border-right: none; margin: 20px; color: #666;
                    th,
                    td { font-size: 1.2em; padding: 10px; border-bottom: 1px solid #e3e3e3; border-right: 1px solid #e3e3e3; }
                    th { background: #f5f5f5; font-weight: 400; color: #666; text-transform: uppercase; }

                    .slide & { margin: 20px 0; width: 100%; }
                    .sortable-chosen {
                        td { background: rgba(0,0,0,.035); cursor: grabbing; }
                    }
}

.list-usage-table__reorder { text-align: center; width: 35px; cursor: grab;
                             svg { width: 15px; height: 15px; fill: #666; }
}
.list-usage-table__usage,
.list-usage-table__sub_category{
    text-align: center; width: 70px;
    a { text-decoration: none; color: #666; }
    a:hover { text-decoration: underline; color: $primary-colour; }
}
.list-usage-table__name {
    input { width: 100%; }
}
.list-usage-table__delete { text-align: center; width: 70px;
                            a { color: $primary-colour; font-size: 16px; text-decoration: none; display: block; padding: 10px; }
}


/*--
==============================
TABLES
NetNames theme overrides
==============================
--*/

$table-border-colour: #ededed;
$table-column-divider-colour: #fff;
$table-text-colour: #666;
$table-header-colour: #333;
$table-row-active: lighten($primary-colour,39%);
$table-stripe: #ededed;
$table-cell-padding: 15px 8px;


.list-table tr.active > td,
.list-table tr.active > th { background: $table-row-active; }
.list-table tr.hover > td,
.list-table tr.hover > th { background: #f2f2f2; }

.col-checkbox { text-align: center; width: 50px; }
.col-tld { width: 95px; }
.col-dns-managed { text-align: center; width: 70px; }
.col-managed-status { width: 150px; }
.col-contact { width: 340px; }

.table-holder { position: relative; }
.table-scroller { border-right: 1px solid $table-border-colour; overflow-x: auto; width: 100%; }
.table-container { position: relative; }
.fixed-table-header { background: #fff; position: absolute; left: 0; top: -1px; }
.fixed-table-columns { background: #fff; position: absolute; left: 0; top: -1px; z-index: 9; border-right: 1px solid $table-border-colour; }

/*--
Table Scroll Controls - Appear on Hover
--*/

.table-scroll-controls { position: fixed; z-index: 10; width: 100%; height: 120px; transition: opacity 250ms; opacity: 0; display: none; bottom: 0; pointer-events: none; }
.table-scroll-controls.available { display: block; }
.table-scroll-controls.active.available { display: block; opacity: 1; }

.table-scroll { width: 40px; height: 40px; background: $primary-colour; display: block; border-radius: 50%; position: absolute; line-height: 40px; text-align: center; color: #fff; cursor: pointer; bottom: -60px; transition: bottom 250ms 250ms, background 250ms; box-shadow: 0 0 20px rgba(0,0,0,0.3); pointer-events: all; }
.table-scroll-controls.active.available .table-scroll { bottom: 20px; }
.table-scroll--left { left: 20px;
                      .glyph-icon:before { transform: scaleX(-1); }
}
.table-scroll--right { right: 20px; }
.table-scroll:hover { background: #333; }
.table-scroll-controls .btn { position: absolute; left: 50%; bottom: -60px; width: 190px; margin: 0 0 0 -95px; text-align: center; transition: bottom 250ms 500ms, background 250ms; }
.table-scroll-controls.active.available .btn { bottom: 23px; }

.list-table--with-sticky tbody {
    th,
    td { height: 67px; }
}

.default-checkbox input { -webkit-appearance: checkbox;
                          -moz-appearance:    checkbox;
                          appearance:         checkbox; }

.list-usage-table .action {
    width: 50px;
    padding: 0;
    overflow: hidden;
}

.list-usage-table .action .action-icon{
    border: none;
    background: none;
    display: block;
    text-align: center;
    color: #f96332;
    padding: 15px;
    transition: background .25s ease-in, -webkit-transform .15s ease-in;
    transition: background .25s ease-in, transform .15s ease-in;
    transition: background .25s ease-in, transform .15s ease-in, -webkit-transform .15s ease-in;
    cursor:pointer;
}

.list-usage-table .action .action-icon:hover, .list-usage-table .action a:hover{
    background: #f5f5f5;
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    text-decoration:none;
}