.autocomplete,
.autocomplete div,
.autocomplete span{
/*	-moz-box-sizing: border-box !important;
	box-sizing: border-box !important;*/
}

.autocomplete{
position:relative;
word-spacing: normal;
text-transform: none;
text-indent: 0px;
text-shadow: none;
text-align: start;
}

.autocomplete .input{
	position:relative;
	z-index:2;
}
.autocomplete .autocomplete_dropdown{
	position:absolute;
	border: 1px solid #ccc;
	border-top-color: #d9d9d9;
	box-shadow: 0 2px 4px rgba(0,0,0,0.2);
	-webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.2);
	cursor: default;
	display:none;
	z-index: 1001;
	margin-top:-1px;
	background-color:#fff;
	min-width:100%;
	overflow:auto;
}
.autocomplete .autocomplete_hint{
	position:absolute;
	z-index:1;
	color:#ccc !important;
	-webkit-text-fill-color:#ccc !important;
	text-fill-color:#ccc  !important;
	overflow:hidden !important;
	white-space: pre  !important;
}

.autocomplete .autocomplete_hint span{
	color:transparent;
	opacity: 0.0;
}

.autocomplete .autocomplete_dropdown > .autocomplete_copyright{
	color:#ddd;
	font-size:10px;
	text-decoration:none;
	right:5px;
	position:absolute;
	margin-top:-15px;
	z-index:1002;
}
.autocomplete .autocomplete_dropdown > div{
	background:#fff;
	white-space: nowrap;
	cursor: pointer;
	line-height: 1.5em;
	padding: 2px 0px 2px 0px;
}
.autocomplete .autocomplete_dropdown > div.active{
	background: #0097CF;
	color: #FFFFFF;
}
