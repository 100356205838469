/*! Forms */
.main-form { position: relative; min-height: 100%;  }
.main-form--with-page-builder { margin-left: 180px; }

.form-panel { background: #fff; width: $max-panel-width; margin: 0 0 20px 20px; position: relative; min-height: 150px;
              &.form-panel--no-min-height { min-height: 0; }
              &:after { content: ''; display: block; position: absolute; left: 0; right: 0; bottom: 0; height: 5px;  }
}
.seo-form .form-panel { margin: 0 auto 20px; max-width: $max-panel-width; width: 100%; }
@media screen and (min-width: 1300px) {
    .form-panel { margin: 0 auto 20px; }
}
.dm-panel { border: 1px solid $secondary-colour; }

.panel-controls { position: absolute; width: 70px; right: -70px; top: 0; list-style: none;
                  li { font-size: 2.5em;  margin: 0 0 20px; }
                  a { text-decoration: none; color: #999; padding: 10px; }
}

.in-panel-controls { position: absolute; right: 20px; top: 0; list-style: none;
                     li { font-size: 1.6em; margin: 0 0 20px; display: inline-block; }
                     a, button { text-decoration: none; color: #999; padding: 0 10px; border: none; background: none; height: 34px; line-height: 34px; }
}

.panel-select-number { padding: 11px 15px; list-style: none; margin: 0; text-align: center; border-bottom: 1px solid #ededed;
                       li { margin: 0 5px; display: inline-block; }
}


.action-buttons { max-width: $max-panel-width; margin: 0 0 0 20px; padding: 0 0 50px; position: relative;
                  &.large { max-width: 980px; margin: 0 auto 20px; padding: 0 0 50px; }
                  .btn-primary { float: right; }
                  .btn-secondary { margin-right: 20px;
                                   .glyph-icon { margin-right: 10px; }
                  }
                  .panel & { padding: 20px; margin: 0; max-width: none; }
                  &.edit-mode { position: fixed; bottom: -200px; left: 150px; right: 0; max-width: none; background: #fff; padding: 33px 20px 30px; z-index: 30; margin: 0; box-shadow: 0 0 10px rgba(0,0,0,.2); animation: inFromBottom 1s ease .25s; animation-fill-mode: forwards; border-radius: 0 0 5px 5px;
                                p { margin: 0; }
                                &::after { content: ''; display: block; height: 2px; position: absolute; left: 0; right: 0; top: 0; background: #ccc; border-radius: 0; }
                  }
                  &.edit-mode--with-page-builder { left: 330px; }
                  &.lister-mode { position: fixed; bottom: -200px; width: 980px; left: 50%; right: 0; max-width: none; background: #fff; padding: 20px; z-index: 30; margin: 0; box-shadow: 0 0 10px rgba(0,0,0,.2);transform: translateX(-50%); margin-left: 75px;
                                  p { margin: 0; }
                  }


                  &.save-mode { background: #fff; box-shadow: 1px 1px 2px rgba(0,0,0,.2); padding: 10px 20px; height: 73px; margin: 0 auto 20px; }
                  &-reason { color: $primary-colour; height: 34px; line-height: 34px; display: inline-block; }
}
@media screen and (min-width: 1300px) {
    .action-buttons { margin: 0 auto;}
}
.list-order-changed .action-buttons.lister-mode { animation: inFromBottom 1s ease .25s; animation-fill-mode:  forwards; }
.fixed-centre-content { max-width: $max-panel-width; margin: 0 auto; }
.has-fixed-footer { padding-bottom: 103px; }

.created-by-info { text-align: center; margin: 0; color: #999; display: table; position: absolute; top: 9px; right: 0; transform: translateY(-50%); font-size: 1.1em; }
.created-divider { margin: 0 10px }

@keyframes inFromBottom {
    from {bottom: -200px; }
    to { bottom: 0; }
}


$form-input-border-radius: 5px;
$form-input-padding: 6px 8px;
$form-input-border: 1px solid #ccc;
$form-input-background-colour: #fff;
$form-input-transition: border-color .2s, box-shadow .2s;
$form-input-colour: #666;

$checkbox-on-colour: #fff;
$radio-on-colour: #000;

$form-help-colour: #999;

$form-horizontal-label-width: 200px;

label { display: block; margin: 0 0 3px; color: #666; }

.lg-input { font-size: 2em; }
.sm-input { font-size: 0.5em; }

/*--
FORM INLINE
--*/

.form-inline h2 { display: inline; font-size: 1.2em; color: $primary-colour; margin: 0 20px 0 0; }
.form-inline p { display: inline-block; margin: 0 10px 0 0; }
.form-inline label { display: inline-block; padding: 0 3px 0 0; }
.form-inline input { width: auto !important; }

/*--
FORM HORIZONTAL
--*/

.form-horizontal label { display: inline-block; width: $form-horizontal-label-width; }
.form-horizontal .checkbox,
.form-horizontal .radio { padding-left: $form-horizontal-label-width + 4; }

/*--
FORM STACKED
--*/

.form-stacked label { display: block; }
.form-stacked input[type="text"],
.form-stacked input[type="password"] { width: 100%; }

/*--
FIELDSET
--*/
fieldset { border: none; padding: 0; margin: 0; }

.title-legend { font-size: 1.2em; color: $primary-colour; padding: 40px 25px 30px; text-align: center; text-transform: uppercase; border-bottom: 1px solid #ededed; display: block; width: 100%; margin: 0; line-height: 1; position: relative;
                &:after { content: ''; display: block; position: absolute; left: 0; top: 0; right: 0; height: 5px; background: linear-gradient(to right, #f96332 0%,#cc0066 100%); opacity: .4;
                }
                &.archived:after { background: linear-gradient(to right, lighten($archived, 20%) 0%, $archived 100%); opacity: .8; }
                &.pending:after {
                    background: linear-gradient(to right, $pending 0%,darken($pending, 20%) 100%); opacity: .8;
                }
                &.live:after,
                    &.published:after {
                    background: linear-gradient(to right, $published 0%,darken($published, 30%) 100%); opacity: .8;
                }
}
.page-slug { color: #666; padding: 10px 0 0; display: block; line-height: 1.1;
             i { text-transform: lowercase; font-style: normal; padding: 0 0 0 10px; margin: 0 0 0 5px; border-left: 1px solid #ccc; }
}
.title-legend--no-border { border: none; }

.dm-panel .title-legend { background: $secondary-colour; color: #fff; }
.colon.hide { display: none; }

.title-legend-with-margin { margin-bottom: 15px; }
.panel-entered-title { color: #666; text-transform: none; margin-left: 0px; }
.panel-entered-title.no-margin { margin-left: 0; }
fieldset + fieldset .title-legend { display: none; }

.title-legend + p { padding-top: 15px; margin-top: 0!important;}

.fieldset-secondary-title { background: #f5f5f5; font-size: 1.2em; text-transform: uppercase; padding: 10px 20px; color: #666; border-bottom: 1px solid #ededed; text-align: center; }

/*--
INPUT DEFAULTS
--*/

input:not(.default), textarea { -moz-appearance: none; -webkit-appearance: none; }
select { -moz-appearance: none; -webkit-appearance: none; }
textarea { height: 100px; resize:vertical; }
.panel-rich-text textarea { height: 500px; width: 100%; display: none; }
.standard-form-input--textarea-short textarea { height: 50px; }
.standard-form-input--textarea-long textarea { height: 200px; }

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="tel"],
input[type="url"],
input[type="datetime"],
input[type="date"],
input[type="number"],
textarea,
select { border: $form-input-border; padding: $form-input-padding; border-radius: $form-input-border-radius; background: $form-input-background-colour; transition: $form-input-transition; color: $form-input-colour; }

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="datetime"]:focus,
input[type="number"]:focus,
textarea:focus,
select:focus { outline: none; border-color: #999; }

select { padding-right: 40px; position: relative; }
.select-wrapper { position: relative; display: inline-block; }
.select-wrapper::after { content: '\f100'; position: absolute; right: 10px; top: 0; font-family: Flaticon; display: block; pointer-events: none; color: #666; font-size: 60%; height: 32px; line-height: 32px; }

.select-wrapper--min-width { width: 200px;
                             select { width: 100%; }
}

input + label { display: inline-block; }

.date-time-input .select-wrapper { /*margin: 0 15px 0 0;*/ }
.date-time-input .select-wrapper.hour { margin: 0 0 0 25px; }
.date-time-input.force-select-width .select-wrapper select { width: 80px; }


/*--
HELP MESSAGING
--*/

.help-block,
.characters-block,
.help-inline { color: $form-help-colour; }

.help-block { display: block; padding: 7px 0 0;
              &:before { content: 'NOTE'; display: inline-block; padding: 2px 5px; font-size: 80%; background: #999; color: #fff; margin: 0 5px 0 0; border-radius: 3px; font-weight: 600; text-shadow: -1px -1px 0 rgab(0,0,0,.6); border: 1px solid #777; box-shadow: 1px 1px 0 rgba(0,0,0,0.4); }
}
.characters-block { display: block; padding: 3px 0 0; }
.help-inline { display: inline-block; margin: 0 0 0 10px; }

.standard-form-input .inline-input + .characters-block { padding-left: 379px; }
.file-upload-holder .inline-input + .characters-block { padding-left: 379px; }

.form-action-right { text-align: right; }

/*--
CHECKBOXES
--*/

.checkbox [type="checkbox"]:not(:checked),
.checkbox [type="checkbox"]:checked { position: absolute; left: -9999px; }
.checkbox [type="checkbox"]:not(:checked) + label,
.checkbox [type="checkbox"]:checked + label { position: relative; padding-left: 25px; cursor: pointer; line-height: 18px; }
.checkbox [type="checkbox"]:not(:checked) + label:before,
.checkbox [type="checkbox"]:checked + label:before { content: ''; position: absolute; left:0; top: 0; width: 18px; height: 18px; border: $form-input-border; background: $form-input-background-colour; border-radius: 2px; transition: $form-input-transition; }
.checkbox [type="checkbox"]:not(:checked) + label:after,
.checkbox [type="checkbox"]:checked + label:after { font-family: Flaticon; content: $font-Flaticon-mark; position: absolute; top: 0; left: 0; width: 18px; text-align: center; font-size: 70%; color: $checkbox-on-colour; background: $primary-colour; border-radius: $form-input-border-radius; box-shadow: 0 0 4px rgba(0,0,0,.6); transition: all .2s; height: 18px; line-height: 18px; }
.checkbox [type="checkbox"]:not(:checked) + label:after { opacity: 0; transform: scale(0); }
.checkbox [type="checkbox"]:checked + label:after { opacity: 1; transform: scale(1); }
.checkbox [type="checkbox"]:checked:focus + label:before,
.checkbox [type="checkbox"]:not(:checked):focus + label:before { outline: none; }

.checkbox-alternative [type="checkbox"] + label { display: inline-block; padding: 0 10px; height: 26px; line-height: 26px; cursor: pointer; border: 1px solid transparent; border-radius: 13px; vertical-align: middle; transition: border-color .25s ease-in, color .25s ease-in; text-transform: uppercase; }
.checkbox-alternative [type="checkbox"]:not(:checked),
.checkbox-alternative [type="checkbox"]:checked { position: absolute; left: -9999px; }
.checkbox-alternative [type="checkbox"]:checked + label { border-color: #999; }

.standalone-checkbox { padding-left: 25%; margin-left: 10px; }

/*--
RADIO BUTTONS
--*/

.radio [type="radio"] + label { display: inline-block; }
.radio [type="radio"]:not(:checked),
.radio [type="radio"]:checked { position: absolute; left: -9999px; }
.radio [type="radio"]:not(:checked) + label,
.radio [type="radio"]:checked + label { position: relative; padding-left: 25px; cursor: pointer; line-height: 20px; }
.radio [type="radio"]:not(:checked) + label:before,
.radio [type="radio"]:checked + label:before { content: ''; position: absolute; left:0; top: 0; width: 20px; height: 20px; border: $form-input-border; background: $form-input-background-colour; border-radius: 10px; transition: $form-input-transition; }
.radio [type="radio"]:checked + label:before  { box-shadow: 0 0 2px rgba(0,0,0,.3); }
.radio [type="radio"]:not(:checked) + label:after,
.radio [type="radio"]:checked + label:after { content: ''; position: absolute; top: 6px; left: 6px; width: 8px; height: 8px; border-radius: 7px; text-align: center; transition: all .2s; background: $primary-colour; display: block;}
.radio [type="radio"]:not(:checked) + label:after { opacity: 0; transform: scale(0); }
.radio [type="radio"]:checked + label:after { opacity: 1; transform: scale(1); }
.radio [type="radio"]:checked:focus + label:before,
.radio [type="radio"]:not(:checked):focus + label:before { outline: none; }

.radio-alternative [type="radio"] + label { display: inline-block; padding: 0 10px; height: 26px; line-height: 26px; cursor: pointer; border: 1px solid transparent; border-radius: 13px; vertical-align: middle; transition: border-color .25s ease-in, color .25s ease-in; text-transform: uppercase; }
.radio-alternative [type="radio"]:not(:checked),
.radio-alternative [type="radio"]:checked { position: absolute; left: -9999px; }
.radio-alternative [type="radio"]:checked + label { border-color: #999; }

.input-group--faux-tab-radios { padding: 0 !important; margin: 0; border-top: 1px solid #ededed; }
.radio-faux-tab { float: left; width: 33.33%; margin: 0; position: relative; }
.radio-faux-tab input { position: absolute; left: -9999em; }
.radio-faux-tab input + label { display: block; padding: 20px; border-left: 1px solid #ededed; cursor: pointer; margin: 0; background: #f6f6f6; border-bottom: 1px solid #ededed;  }
.radio-faux-tab:first-child input + label { border-left: none; }
.radio-faux-tab input:checked + label { background: #fff; border-bottom: #fff; }

.radio-faux-tab::after { position: absolute; display: block; right: 20px; top: 50%; height: 20px; width: 20px; line-height: 20px; transform: translateY(-50%); content: ''; font-family: "Flaticon"; font-style: normal; font-weight: normal; text-align: center;  }



.image-url-options { padding-bottom: 5px; }

.radio-inline p,
.checkbox-inline p { display: inline-block; margin-right: 10px; }

.dm-panel .radio-alternative [type="radio"] + label,
.dm-panel .checkbox-alternative [type="checkbox"] + label { border-radius: 5px; }
.dm-panel .checkbox-alternative [type="checkbox"]:checked + label ,
.dm-panel .radio-alternative [type="radio"]:checked + label { border-color: $secondary-colour; color: $secondary-colour; }

/*--
STANDARD FORM SIZES
--*/
.standard-form-indented { padding-left: calc(25% + 10px); }
.standard-form-input { padding: 0 15px 0; margin: 0 0 15px;
                       label { display: inline-block; width: 25%; text-align: right; padding: 0 10px 0 0; margin: 0; }
                       .help-block,
                       .characters-block { margin-left: 25%; padding-left: 4px; }
                       input[type="text"],
                       input[type="email"],
                       input[type="password"],
                       input[type="date"],
                       input[type="tel"],
                       input[type="url"],
                       input[type="number"],
                       input[type="datetime"] { width: 36%; }
                       textarea { width: 73%; }
                       .select-wrapper { max-width: 36%; }
                       select { max-width: 100%; }

                       .inline-input,
                       .inline-checkbox { margin-left: 10px; }
                       .inline-checkbox label { width: auto; text-align: left; }

                       .inline-input label { width: auto; }
                       .inline-input input { width: 150px; }
}

.standard-form-input.large {

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="date"],
    input[type="tel"],
    input[type="url"],
    input[type="number"],
    input[type="datetime"] { width: 73%; }
    textarea { width: 73%; }
}


.file-upload-holder .help-block { margin-left: 25%; padding-left: 10px; }
.current-doc { display: inline-block; padding: 10px; border: 1px solid #ededed; background: #ededed; border-radius: 5px; }

.price-input,input[type="text"].price-input { width: 100px !important; background: #fff url(../img/pound.png) no-repeat 8px center; padding-left: 17px; }
.number-input { width: 80px !important; }
.postcode-input { width: 80px !important; }
.lat-long-input { width: 130px !important; }
.date-input { width: 120px !important; }
.time-input { width: 60px !important; }
.short-input { width: 180px !important; }
.button-text-input { max-width: 160px; }
.select-time { width: 70px; }

.faux-label { display: inline-block; width: calc(25% - 5px); text-align: right; padding: 0 10px 0 0; margin: 0 0 0 10px; color: #666; }

.status-input { text-align: center; padding: 0 0 10px;}

.venues-facilities-input { text-align: center; padding: 0 0 10px;}

.venues-opening-time-input { text-align: center; padding: 0 0 10px;}

/*--
FULL WIDTH FORM SIZES
--*/

.full-width-form-input { padding: 0 15px 0; margin: 15px 0 15px;
                         label { display: inline-block; width: 25%; text-align: right; padding: 0 10px 0 0; margin: 0; }
                         .help-block,
                         .characters-block { margin-left: 25%; padding-left: 4px; }
                         input[type="text"],
                         input[type="email"],
                         input[type="password"],
                         input[type="date"],
                         input[type="tel"],
                         input[type="url"],
                         input[type="datetime"],
                         textarea { width: 73%; }
}

/*--
REPLACE FILE INPUT WITH STYLED
--*/

.inputfile { width: 0.1px; height: 0.1px; opacity: 0; overflow: hidden; position: absolute; z-index: -1; }
.file-input-label { cursor: pointer; padding: 10px 15px 10px 35px; margin: 0 15px 0 0; position: relative; border: 1px solid $primary-colour; border-radius: 5px; color: $primary-colour; max-width: 33%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
.file-input-label * { pointer-events: none; }
.file-input-label .glyph-icon { font-size: 150%; position: absolute; left: 10px; top: 50%; margin-top: -13px; }
.file-input-label.btn { border-radius: 25px; max-width: none; color: #fff; padding: 10px 15px; }

.file-upload-holder { margin: 0 0 15px;
                      * { vertical-align: middle; }
                      .inline-input label { width: auto; display: inline-block; margin: 0 10px 0 0; }
                      .inline-input input { width: 150px; }
}

/*--
BORDER ON FORM PARAGRAPHS
--*/

.form-input-border-b { border-bottom: 1px solid #ededed; padding-bottom: 15px; }

.standard-form-input--textarea label { vertical-align: top; }

/*--
TRIGGER TO REVEAL MORE
--*/

.checkbox-trigger { text-align: center; border-top: 1px solid #ededed; padding: 15px 15px 5px 15px; line-height: 20px; }

/*--
INPUT GROUPS
--*/

.input-group { padding-left: 15px; padding-right: 15px; }
.input-group legend { font-size: 1.2em; display: inline-block; width: 25%; text-align: right; padding: 0 10px 0 0; margin: 0; float: left; color: #666; }
.input-group--alternative-radios legend,
.input-group--alternative-checkboxes legend { height: 26px; line-height: 26px; }
.input-group { margin: 0 0 10px; }
.input-group .radio,
.input-group .radio-alternative,
.input-group .checkbox,
.input-group .checkbox-alternative { display: inline-block; margin: 0 15px 10px 5px; }
.input-group--alternative-radios .radio,
.input-group--alternative-radios .radio-alternative,
.input-group--alternative-checkboxes .checkbox,
.input-group--alternative-checkboxes .checkbox-alternative { margin-right: 5px; }

.input-group--equally-spaced p { width: 20%; }

.input-group--alternative-checkboxes,
.input-group--alternative-radios { margin: 0 0 5px; }

.input-group--alternative-checkboxes + .input-group--alternative-radios,
.input-group--alternative-checkboxes + .input-group--alternative-checkboxes,
.input-group--alternative-radios + .input-group--alternative-radios,
.input-group--alternative-radios + .input-group--alternative-checkboxes { padding-top: 15px; }

.input-group--list .checkbox { display: block; margin-left: 25%; }

.input-group-input-list { display: inline-block; width: 70%; }

legend + .input-group { padding-top: 15px; }

.external-link-group { margin: 0 0 15px; border-bottom: 1px solid #ededed; }

.input-group-big-decision { text-align: center; position: relative;
                            legend { width: 100%; }
                            legend span { font-size: 1.2em; display: block; text-align: center; padding: 10px 0 0; margin: 0 0 10px; color: #666; border-top: 1px solid #ededed; }
                            p { display: inline-block; padding: 10px; }
                            + div .image-upload { margin-top: 0; }
                            &:after { position: absolute; left: 30%; right: 30%; }
}

/*--
CENTRE ALIGN AN ACTION BUTTON
--*/

.centre-actions { list-style: none; margin: 0; padding: 0 0 20px; text-align: center;
                  li { display: inline-block; margin: 0 5px; }
                  .btn { width: 165px; }
}
.centre-actions--separate { border-top: 1px solid #ededed; padding: 20px 0; margin: 20px 0 0; background: #f6f6f6; }

.centre-actions--wide-buttons {
    .btn { width: 250px; }
}
.in-page-action { text-align: center; padding: 20px 0; }

/*--
TOP RIGHT BUTTON
--*/
.btn--manage { position: absolute; right: 20px; top: 36px; font-size: 1.2em; color: #999; text-decoration: none;
               span { margin: 0 5px 0 0; }
}

/*--
TOP RIGHT BUTTON
--*/
.empty--text-message { text-align: center; background: #f5f5f5; padding: 40px 40px 30px; }

/*--
IMAGE UPLOADS
--*/

.image-upload { position: relative; border: 1px solid #ededed; margin: 10px 10px 20px; background: linear-gradient(to left, #333 , #666); background: repeating-linear-gradient(-45deg, #fff, #fff 5px, #f6f6f6 5px, #f6f6f6 7px);
                .trigger-reveal & { border-top: 1px solid #ededed; }
                .image-upload-full & { display: inline-block; }
                &.dark { background: #111; }
                &.light { background: #f6f6f6; }
}


.image-upload-empty { text-align: center; padding: 60px 0 80px;
                      .image-upload-dimensions { margin: 0 auto; }
}
.image-upload-dimensions { width: 100px; text-align: center; color: #666; padding: 10px 0;
                           p { margin: 0; }
                           .glyph-icon { font-size: 260%; }
                           .image-upload-full & { background: #fff; border-radius: 5px; position: absolute; left: 10px; top: 10px; box-shadow: 0 0 3px rgba(0,0,0,.2); color: #666; }
}
.image-uploads-holder { min-height: 100px; position: relative; padding: 20px; border-top: 1px solid #ededed; }
.image-upload-full { text-align: center; padding: 20px;
                     .js-reusable-image-preview { box-shadow: 0 0 3px rgba(0,0,0,.2); }
}
.uploaded-image { display: table; max-width: 760px; margin: 0 auto; position: relative; min-height: 200px;
                  img { width: 100%; box-shadow: 1px 1px 1px rgba(0,0,0,.2); }
}
.uploaded-image-change { width: 100px; text-align: center; color: #666; padding: 13px 0 11px; background: #fff; border-radius: 5px; position: absolute; left: 10px; top: 90px; height: 74px; box-shadow: 0 0 3px rgba(0,0,0,.2);
                         p { margin: 0; line-height: 1.1; }
                         .glyph-icon { font-size: 120%; margin: 0 0 6px; display: inline-block; }
}
.uploaded-image-alt-tag-holder { position: absolute; left: 50%; bottom: 20px; width: 260px; margin: 0 0 0 -130px; background: #fff; border: 1px solid #ccc; border-radius: 5px;
                                 label { display: inline-block; width: 50px; padding: 0 0 0 10px; }
}
input[type="text"].uploaded-image-alt-tag { width: 200px; border: none; }
.optional-inputs,
.trigger-reveal { display: none; }

.uploaded-image-change-panel-colour { position: absolute; top: 10px; right: 10px;
                                      ul { list-style: none; margin: 0; padding: 0; }
                                      li { display: inline-block; width: 24px; height: 24px; border-radius: 0; border: 2px solid #f6f6f6; cursor: pointer; }
                                      li.on { border-color: $primary-colour; }
                                      .dark { background: #111; }
                                      .light { background: #fff; }
}

.uploaded-image { max-height: 450px;}

/*--
WYSIWYG
--*/



/*--
MAP
--*/

.form-map { height: 400px; margin: 0 0 15px; }
.form-map.large { height: 800px; margin: 0; }

/*--
FAQs
--*/

.form-faq-header { background: #f5f5f5; border-top: 1px solid #ededed; border-bottom: 1px solid #ededed; padding: 10px; margin: 0 0 15px; position: relative;
                   h2 { font-size: 1.2em; text-align: center; margin: 0; }
}

/*--
TWISTER HEADINGS
--*/

.twister-heading { font-size: 1.2em; padding: 10px; border-top: 1px solid #ededed; border-bottom: 1px solid #ededed; margin: 0 20px 15px; cursor: pointer; background: #f5f5f5; }
.twister-heading:hover { color: #333; }
.twister-heading:before { content: '\f102'; margin: 0 10px 0 0; font-family: Flaticon; font-size: 75%; }

.twister-heading.active:before { content: '\f106'; }

.js-twister-content { display: none; }

.file-input-label.btn { margin-left: 20px; }

/*cropper file errors*/
.cropper-file-errors { color: #999; font-size: 1.2em; display: block; position: absolute; bottom: 10px; left: 10px; right: 10px; text-align: center; border-radius: 4px; }
.cropper-file-errors { height: 60px; line-height: 60px; opacity: 0; margin: 0; box-shadow: 0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2); }
.cropper-file-errors.red { color: #fff; display: block; background: $negative; opacity: 1; }
.cropper-file-errors.spinner { position: fixed; left: 0; right: 0; top: 0; background: rgba(255,255,255,.8); height: 100%; z-index: 1000; display: none; }
.cropper-file-errors.spinner { display: block; }
.panel-rich-text .cropper-file-errors.spinner { display: none; }

.cropper-file-errors.spinner:after {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 3px solid lighten($primary-colour,30%);
    border-right: 3px solid lighten($primary-colour,20%);
    border-bottom: 3px solid  lighten($primary-colour,10%);
    border-left: 3px solid $primary-colour;
    transform: translateZ(0);
    animation: load8 .5s infinite linear;
    content: '';
    display: block;
}
.cropper-file-errors.spinner:before {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 3px solid lighten($primary-colour,30%);
    border-right: 3px solid lighten($primary-colour,20%);
    border-bottom: 3px solid  lighten($primary-colour,10%);
    border-left: 3px solid $primary-colour;
    content: '';
    display: block;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: fixed; left: 50%; top: 50%; margin-left: -15px; margin-top: -15px;
    animation: load8 .5s infinite linear; animation-direction: reverse;

}
.cropper-file-errors.spinner:after {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: fixed; left: 50%; top: 50%; margin-left: -20px; margin-top: -20px;
}
@keyframes load8 {
    0% {
        transform: rotate(0deg) scale(1,1);
    }
    50% {
        transform: rotate(180deg) scale(1.5,1.5);
    }
    100% {
        transform: rotate(360deg) scale(1,1);
    }
}

.cropper-file-errors-snippet-container { position: absolute; bottom: 5px; right: 150px; width: 40px; height: 40px; margin: 0; }
.panel-rich-text .cropper-file-errors.spinner { bottom: 25px; }
.save-button-overlay { position: fixed; z-index: +10000; overflow: hidden; top: 0; left: 0; right: 0; bottom: 0; opacity: 0; background: rgba(255,255,255,.8); transition: opacity .5s ease; pointer-events: none; }
.save-button-overlay.show { opacity: 1; }
.save-button-overlay .cropper-file-errors.spinner { margin: 0 0 -20px -20px; left: 50%; bottom: 50%; }

/*additional styles*/
fieldset.no-legend legend { display: none; }
fieldset.fixed-width-p.input-group.fixed-width-p .checkbox { width: 230px; }


#infobox { width: 260px; margin: 0 0 0 -130px; background: #fff; padding: 10px; box-shadow: 0 0 20px rgba(0,0,0,0.3); font-size: 10px; }
#infobox:before { content: ''; width: 0; height: 0;  border-left: 6px solid transparent; border-right: 6px solid transparent; border-bottom: 6px solid #fff; position: absolute; top: -6px; left: -12px; }
#infobox p {  margin: 0; font-size: 1.35em; }
#infobox h2 { font-size: 2.2em;  color: #000; margin: 0 0 18px 0; color: $secondary-colour; }
#infobox a { color: $primary-colour; }
#infobox a:hover { color: $secondary-colour; text-decoration: none; }
.map-centre-address { font-size: 1.35em; margin: 0 0 10px !important; }
.map-contact { list-style: none; padding: 0; margin: 0; }
.map-contact li { font-size: 1.35em; }

.sidebar-info { min-height: 1px; padding: 20px 20px 20px 120px; position: relative;
                h2 { font-size: 2.4em; margin: 0; font-weight: 300; color: #666; }
}
.sidebar-mockup { position: absolute; left: 0; top: 0; bottom: 0; width: 100px; border-right: 1px solid #ededed;
                  &:after { content: ''; display: block; background: #ededed; left: 10px; top: 10px; bottom: 10px; width: 55px; position: absolute; }
                  &:before { content: ''; display: block; background: $primary-colour; right: 10px; top: 10px; bottom: 10px; width: 20px; position: absolute; }
}

.inline-management-content { background: #f6f6f6; padding: 20px 0 10px; border-top: 1px solid #ededed; }

.inline-add-form { border-top: 1px solid #ededed; padding: 20px 0 0; }

.hide-calendar .calentim-calendar { display: none; }

.calendar-wrapper { padding-top: 20px; }
