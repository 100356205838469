/*! Typography */
body { font-size: 62.5% !important; font-family: $font-stack; min-width: 1240px; }

h1, h2, h3, h4, h5, h6, h7 { font-weight: normal; margin: 0 0 15px; line-height: 1.2; }

h1 { font-size: 5em; }
h2 { font-size: 4.5em; }
h3 { font-size: 4em; }
h4 { font-size: 3.5em; }
h5 { font-size: 3em; }
h6 { font-size: 2.5em; }

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small { font-size: 65%; color: #666; }

p, li, dl, address { font-size: 1.2em; margin: 0 0 10px; line-height: 1.5; }
p.lead { font-size: 2em; }

address { font-style: normal; }

a { color: $link-colour; }
a:hover { text-decoration: none; }

/* Lists */

ul, ol { margin: 0; padding: 0 0 0 15px; }
ul ul, ol ol, ul ol, ol ul { padding: 15px 0 0 30px; }
ol ol { list-style: lower-roman; } 
li li { font-size: 1em; }

hr { border: 0; border-bottom: 1px dashed #ccc; background: #999; margin: 30px 0; }

dl { margin: 0 0 15px; }
dt { font-weight: 700; }
dd { margin: 0; }
dt, dd { line-height: 1.45; }

.dl-horizontal dt { clear: left; float: left; overflow: hidden; text-align: right; text-overflow: ellipsis; white-space: nowrap; width: 160px; }
.dl-horizontal dd { margin-left: 180px; }
.dl-horizontal dd:before { content: ''; display: table; }

blockquote { font-family: serif; margin: 0; padding: 15px 0; }
blockquote > p { font-size: 3em; line-height: 1.2; }
blockquote > p:before { content: open-quote; }
blockquote > p:after { content: close-quote; }
blockquote footer { font-family: sans-serif; font-size: 1.3em; }
blockquote footer:before { content: '-'; margin: 0 10px 0 0; }

.quote-outdented { margin-left: -50px; }
.quote-indented { padding-left: 20px; border-left: 5px solid #ccc; }
.quote-pullout { width: 50%; margin: 0 20px 10px 0; float: left; font-style: italic; max-width: 400px; padding: 20px; background: #e6e6e6; }
.quote-pullout > p { font-size: 2em; }

@media only screen and (min-width: $sm-width) {
	
.quote-pullout { width: 50%; margin: 0 20px 10px 0; float: left; font-style: italic; max-width: 400px; padding: 20px; background: #e6e6e6; }
.quote-pullout > p { font-size: 3em; }

}

.quote-right { text-align: right; }
.quote-right.quote-pullout { float: right; margin: 0 0 10px 20px; }
.quote-right.quote-indented { border-left: none; border-right: 5px solid #ccc; padding-right: 20px; padding-left: 0; }
