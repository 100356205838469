/*! Add Widget */

.add-a-widget-holder { width: 180px; background: #f6f6f6; padding: 83px 0 0; border-right: 1px solid #ccc; overflow: hidden; }

.add-a-widget-track { position: absolute; left: -180px; top: -20px; width: 180px; background: #f6f6f6; padding: 0; bottom: 0; border-right: 1px solid #ccc; }

.add-a-widget ul { list-style: none; margin: 0; padding: 0; overflow: auto; max-height: 325px; 
	li { border-bottom: 1px solid rgba(0,0,0,.1); margin: 0; font-size: 1.2em; line-height: 1.1; }
	a,button { color: #333; text-transform: uppercase; display: block; text-align: left; padding: 20px; text-decoration: none; transition: background .25s ease-in; border: 0; width: 100%; }
	a:hover, button:hover { background: #fff; color: $primary-colour; }
    &:hover { margin-right: 0; }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: $primary-colour;
  }
::-webkit-scrollbar-thumb:hover {
  background: darken($primary-colour,2%);
}
::-webkit-scrollbar-thumb:active {
  background: darken($primary-colour,4%);
}
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.2);
}
::-webkit-scrollbar-track:hover {
  background: rgba(0,0,0,0.3);
}
::-webkit-scrollbar-track:active {
  background: rgba(0,0,0,.5);
}
::-webkit-scrollbar-corner {
  background: transparent;
}


.add-heading { font-size: 3em; display: block; padding: 20px; color: #333; text-align: center; border-bottom: 1px solid rgba(0,0,0,.1); height: 83px; position: absolute; left: 0; top: 0; right: 0; }

.add-a-widget--dev { background: #ddd; top: 530px; padding: 0;
    .add-heading { border-bottom: 1px solid rgba(255,255,255,.4); display: none; }
    ul { 
        li { border-bottom: 1px solid rgba(255,255,255,.3); }
        a { background: #ccc; }
        a:hover { background: #ddd; }
    }
}

.relative { position: relative; }


.fixedsticky {
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -ms-sticky;
	position: -o-sticky;
	position: sticky;
    top: 0;
}
/* When position: sticky is supported but native behavior is ignored */
.fixedsticky-withoutfixedfixed .fixedsticky-off,
.fixed-supported .fixedsticky-off {
	position: static;
}
.fixedsticky-withoutfixedfixed .fixedsticky-on,
.fixedsticky-on {
	position: fixed; 
}
.fixedsticky-dummy {
	display: none;
}
.fixedsticky-on + .fixedsticky-dummy {
	display: block;
}

