/*_buttons */
.lister-panel--categories .btn, .add-container .btn { height: 29px; padding-top: 0; padding-bottom: 0; line-height: 29px; }


/*.btn--kb-add-category {  margin: -1px 0 0 15px; }*/
.btn--kb-fixed-width { width: 140px; }
.btn--kb-fixed-width-2 { width: 162px; }
.btn-subcategory { color: $primary-colour; border: 1px solid $primary-colour; width: 162px; padding-left: 40px;  padding-right: 0; text-align: left; text-transform: capitalize; position: relative; }
.btn-subcategory:hover,.btn-subcategory.on { background: $primary-colour; color: #fff;  }
.btn-subcategory:before { font-family:"Flaticon"; content: "\f100"; position: absolute; top: 8px; left: 19px; transform: rotate(-90deg); line-height: 1em; }

.btn-cancel-add-subcategory { color: #fff; border: 1px solid #6d6c6c; background: #6d6c6c; }
.btn-cancel-add-subcategory:hover,.btn-subcategory.on { background: #fff; color: #6d6c6c;  }

/*_filter-panel*/
.num-results-holder.num-results-holder--no-border { border: 0; }
.filter-panel .num-results-holder-quicklinks-alt { top: 16px; }
.num-results-holder.num-results-holder--industries-bottom { width: 670px; margin: auto; padding: 15px 0; }

.column-kb-date { width: 110px; }
.column-kb-article {  }
.column-kb-category { width: 120px; }
.column-kb-subcategory {}
.list-table .column-kb-td-article { width:420px; max-width:420px; height:auto; overflow:hidden; text-overflow: ellipsis; white-space:nowrap; padding-right: 70px; }
.column-kb-categories-category { width: 330px; }
.column-kb-categories-subcategories { width: 340px; }
.column-kb-categories-articles { width: 112px; text-align: center; }
.column-kb-categories-delete { width: 108px; text-align: center;  }

.column-kb-industries-industry { width: 330px; }
.column-kb-industries-articles { width: 115px; text-align: center; }
.column-kb-industries-delete { text-align: center; }

/*_navigation*/

.num-results-holder-quicklinks-alt { position: absolute; display: block; top: 8px; right: 11px; list-style: none; padding: 0;  
    li { float: left; margin: 0 0 0 8px; }
    a { background-color: #fff; border: 1px solid #fff; color: $pagination-active-bg; float: left; margin-left: -1px; padding: 6px 16px; position: relative; text-decoration: none; line-height: 1.4; transition: border-color .25s ease-in; text-transform: uppercase; border-radius: 25px; }
    
    a:focus, 
    a:hover { border: 1px solid $pagination-active-bg; }    

    li.on > span,
    li.on > a,
    li.on > a:hover { border: 1px solid $pagination-active-bg; cursor: default; }
}

/*forms*/
//.panel select { max-width: 160px; }
//.panel .select-wrapper { margin-right: 10px; }
//textarea { height: 124px; }

.title-legend.title-legend--kb { color: #333333; background: #e8e6e6; text-align: left; }

.one-third-width-form-input { padding: 0 15px 0; margin: 0 0 15px; position: relative;
	label { display: inline-block; width: 25%; text-align: right; padding: 0 10px 0 0; margin: 0; }
    label.two-lines { position: relative; height: 21px; }
    label.two-lines span { position: absolute; display: block; top: -5px; left: 0; padding: 0 10px 0 0; }
	.help-block { margin-left: 25%; padding-left: 4px; }
	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="date"],
	input[type="tel"],
	input[type="url"],
	input[type="datetime"],
	textarea { width: 24.3%; }
}

.star-rating-container { height: 16px; overflow: auto;
    label { width: auto; margin: 0 15px 0 35px; }    
    span.stars { background:url(../img/stars-grey.png) no-repeat left 0; width: 90px; height: 16px; position: relative; display: inline-block; top: 2px; right: 0; }
    span.star { display:block; width: 18px; height: 16px; background:url(../img/stars-grey.png) no-repeat 0 0; float: left; cursor: pointer; }
    span.star.on { background:url(../img/star.png) no-repeat 0 0; }
}

.form-panel.form-panel--no-min-height { min-height: 0; }


.checkbox-alternative--kb [type="checkbox"] + label { min-width: 100px; line-height: 28px; border: 1px solid #999; text-align: center; border-radius: 5px; height: 30px; background: #ededed; }
.checkbox-alternative--kb [type="checkbox"]:not(:checked), 
.checkbox-alternative--kb [type="checkbox"]:checked + label { color: #fff; background: #74a631; }

fieldset.padding-left { padding-left: 206px; }

.action-buttons.action-buttons--large { max-width: 1000px; }

fieldset.tag-container { padding: 15px 15px 0 206px; margin: 0 0 5px; }
fieldset.tag-container a {  display: inline-block; padding: 0 10px; min-width: 100px; line-height: 28px; border: 1px solid #999; text-align: center; border-radius: 5px; height: 30px;
background: #ededed; margin: 0 5px 10px 5px; font-size: 1.2em; color: #fff; background: #74a631; text-decoration: none; position: relative; }
fieldset.tag-container a::after { content:"x"; position: absolute; display: block; top: 2px; right: 5px; line-height: 1em; }
 
.checkbox-alternative [type="checkbox"] + label { text-transform: capitalize; }

/*in-page-navigation*/
.in-page-navigation-panel.in-page-navigation-panel--white-bg { background: #fff; max-width: 1000px;
    .language-selector a { color: $primary-colour; }
    .language-selector li.on a, .language-selector a:hover { border-color: $primary-colour;  }
}


/*lister-panel*/
.lister-panel.lister-panel--small { max-width: 800px; }
.lister-panel.lister-panel--large { max-width: 1000px; }
.categories-delete { color: $primary-colour; font-size: 2em; text-align: center; }
.td-delete,.td-articles { text-align: center; }
.td-articles.td-articles--no-articles { color: $primary-colour; }
.icon-action--reorder { font-size: .7em; }
.list-table .icon-action.icon-action--reorder a { padding: 1.5px; }

.lister-panel--categories, .lister-panel--industries { 
    input[type="text"] { width: 230px; }
}

.lister-panel--categories {
    
    tr.tr-subcategory { display: none; opacity: 0; transition: opacity 500ms linear;
        div { display: none; }
        .icon-action a { color: #666; }
    }
    
    tbody.show-subcategories {
        tr.tr-subcategory { display: table-row; opacity: 1; }
        tr.tr-category { background: #f5f5f5; 
            .btn-subcategory { background: $primary-colour; color: #fff; }
            .btn-subcategory:before { transform: rotate(0deg); }
        }    
    }

}

.lister-panel--industries { 
    .list-table { width: 670px; margin: auto; border-left: 1px solid #e3e3e3;  }
}

.lister-panel--tags {
    .table-container-container  { overflow: auto;
        .table-container { width :50%; float: left;
            .list-table { border-left: 1px solid #e3e3e3; }
        }
        .table-container--left { padding: 0 10px 0 20px; }
        .table-container--right { padding: 0 20px 0 10px; }
    }
}


.add-container {
    .add-container-input { display: inline-block; position: relative; opacity: 0; visibility: hidden; /* transition: all 250ms linear;  */
        .btn { height: 29px; padding-top: 0; padding-bottom: 0; line-height: 29px; }
        input[type="text"] { margin: 0 14px; position: absolute; display: block; top: -1px; left: 0px; width: 230px; /*transition: left 250ms linear;*/ }
        .btn.btn-primary { position: absolute; display: block; top:-1px; left: 0px; /*transition: left 250ms linear; */ }
}}

.add-container.show-input { 
    .add-show-input { display: none; }
    .add-container-input { display: inline-block; opacity: 1; visibility: visible; 
        input[type="text"] { left: 162px; }
        .btn.btn-primary { left: 420px; }
    }
}

.form-panel .add-container.show-input {
    .add-container-input { 
        input[type="text"] {  left: 140px; width: 182px;  }
        .btn.btn-primary {  left: 350px; }
    }
}



.add-container-input.on {
    display: inline-block;
}



