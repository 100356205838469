/*! Overlays */

.overlay-bg { background: rgba(0,0,0,.5); position: fixed; left: 0; top: 0; width: 100%; height: 100%; visibility: hidden; opacity: 0; transition:visibility 0s linear 0.25s,opacity 0.25s linear; }
.overlay-bg.overlay-bg-visible { visibility: visible; opacity: 1; transition-delay:0s; }

.overlay { visibility: hidden; opacity: 0; position: absolute; left: 50%; top: 50%; width: 500px; height: 300px; background: #fff; margin: -250px 0 0 -250px; transform: scale(.5,.5); transition:visibility 0s linear 0.25s,opacity 0.25s linear,transform 0.25s linear; }

.overlay.overlay-visible { visibility: visible; opacity: 1; transform: scale(1,1); box-shadow: 5px 5px 5px rgba(0,0,0,.3); transition-delay:0s; }

.overlay-close { position: absolute; right: 10px; top: 10px; cursor: pointer; }

.overlay-content-holder { overflow: auto; height: 100%; padding: 50px 20px 20px; }

.overlay.overlay-full { width: 100%; height: 100%; left: 0; top: 0; margin: 0; }

.overlay-container.overlay-visible { display: block; }

.overlay-modal-yes-no { 
    .overlay-article { padding: 50px 20px; text-align: center; display: flex; align-items: center; justify-content: center; width: 400px !important; height: 220px !important; margin: 0 !important; margin: -110px 0 0 -200px !important; }
    h2 { font-size: 2em; color: #666; max-width: 300px; }
    ul { list-style: none; padding: 20px 0 0;
        li { display: inline-block; margin: 0 20px; }
        .btn { min-width: 80px; }
    }
}