/*! Framework */

body {  background: #ededed; }

.main-content { position: absolute; padding: 50px 0 100px; left: 150px; right: 0; bottom: 0; top: 0;
    &--home { padding-top: 0; }
}

img { max-width: 100%; height: auto; }

.panel { box-shadow: 1px 1px 2px rgba(0,0,0,.2); border-radius: 0 0 5px 5px; }







@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}