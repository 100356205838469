.in-page-navigation-panel { background: #fff;  margin: 0 0 20px; padding: 40px 0 0 0; border-bottom: 1px solid #ccc; }
.in-page-navigation-panel--with-page-builder { padding-left: 180px; }


.in-page-navigation { max-width: $max-panel-width; margin: 0 0 0 20px; position: relative;
	ul { list-style: none; margin: 0; padding: 0; }
	li { display: inline; margin: 0 40px 0 0; padding: 0; }
	a { display: inline-block; text-transform: normal; color: #666; text-decoration: none; text-align: center; transition: all .25s ease; border-bottom: 3px solid transparent; padding: 0 0 20px 0; }
	li.on a { border-bottom: 3px solid $primary-colour; color: #333; }
	a:hover { border-bottom: 3px solid #999;  }
    
    a.variable-width { width: auto; padding: 0 16px; }
}

@media screen and (min-width: 1300px) {
.in-page-navigation { margin: 0 auto; }
}