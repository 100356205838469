/*! Reset Password or Forgotten password Form Rules*/

.password-rules { position: absolute; top: -43px; left: 300px; border: 1px solid #ccc; left: 500px; border-radius: 5px; background: #fff; padding: 15px 15px 0; z-index: 10; box-shadow: 0 0 10px rgba(0,0,0,.2); width: 280px;
    &:after { display: block; content: ''; width: 0; height: 0; border-style: solid; border-width: 7px 7px 7px 0; border-color: transparent #999 transparent transparent; position: absolute; top: 53px; right: 100%; }
}

.password-rules--hidden { transition: all .15 ease; display: none; }

.password-rules-list { list-style: none; margin: 0 -15px; padding: 0; background: #ededed; border-radius : 0 0 5px 5px; overflow: hidden;
    li { border-top: 1px solid #ccc; padding: 12px 15px 12px 50px; margin: 0; position: relative; transition: background .25s ease; animation: slideIn .2s ease both;
        &.valid { background: #fff; }
        &:nth-child(1) { animation-delay: 0s; }
        &:nth-child(2) { animation-delay: .05s; }
        &:nth-child(3) { animation-delay: .15s; }
        &:nth-child(4) { animation-delay: .2s; }
        &:nth-child(5) { animation-delay: .25s; }
        &:nth-child(6) { animation-delay: .3s; }
        &:nth-child(7) { animation-delay: .35s; }
        &:nth-child(8) { animation-delay: .4s; }
        &:nth-child(9) { animation-delay: .45s; }
    }
}

.invalid-symbol,
.valid-symbol { position: absolute; left: 15px; top: 24px; transform: translateY(-50%);
    svg { height: 20px; width: 20px; }

    .path { stroke-dasharray: 1000; stroke-dashoffset: 0;
      &.circle { animation: dash .9s ease-in-out; }
      &.line { stroke-dashoffset: 1000; animation: dash .9s .35s ease-in-out forwards; }
      &.check { stroke-dashoffset: -100; animation: dash-check .9s .35s ease-in-out forwards; }
    }
}

.valid-symbol { display: none; }
.valid {
    .invalid-symbol { display: none; }
    .valid-symbol { display: block; }
}

@keyframes dash {
  0% { stroke-dashoffset: 1000; }
  100% { stroke-dashoffset: 0; }
}

@keyframes dash-check {
  0% { stroke-dashoffset: -100; }
  100% { stroke-dashoffset: 900; }
}

@keyframes slideIn {
  0% { transform: translateX(-300px); }
  100% { transform: translateX(0); }
}
