/*! Pick from existing */

.dm-reusable-images { height: 100%; overflow: auto; margin: 0 -20px; padding: 10px; }
.dm-reusable-img:hover { border-color: $primary-colour; }

.reusable-image-holder { display: flex; flex-wrap: wrap; }
.reusable-image { width: 20%; padding: 10px; position: relative; transition: all .25s; 
&:hover { transform: scale(1.05); }
}
.reusable-image-img { box-shadow: 0 0 5px rgba(0,0,0,.4); cursor: pointer;
    
}

.overlay-reusable-images {
    .overlay-content { width: 900px !important; margin-left: -450px !important; }
}

.reusable-image-usage { position: absolute; left: 8px; top: 8px; background: #ededed; box-shadow: 0 0 2px rgba(0,0,0,.4); width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; font-size: 1.1em;  transition: all .25s; }
.reusable-image-delete { position: absolute; left: 8px; top: 8px; background: #ededed; box-shadow: 0 0 2px rgba(0,0,0,.4); width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; font-size: 1.4em; color: #666;  transition: all .25s; 
    &:hover { color: $primary-colour; animation: iconPulse .5s infinite;  }
}

@keyframes iconPulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05);}
    100% { transform: scale(1); }
}