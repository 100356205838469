/*! Language Selector */

.languages { position: relative; margin: 10px;
    &__selector { display: table; margin: 0; width: 100%; background: rgba(0,0,0,.1);
        a { height: 40px; padding: 0 10px; text-transform: uppercase; color: #fff; text-decoration: none; display: table-cell; vertical-align: middle; }
        .glyph-icon { font-size: 70%; margin-left: 10px; float: right; position: relative; top: 3px; }
        svg { width: 15px; height: 15px; display: inline-block; vertical-align: middle; margin-right: 5px; position: relative; top: -1px; border: 1px solid rgba(255,255,255,.4); }
        .active .glyph-icon { transform: rotate(180deg);}

    }
    &__list {  background: rgba(0,0,0,.1); position: absolute; min-width: 100%; margin: 0; z-index: 1000; height: 0; width: 100%; overflow: hidden; visibility: hidden; transition: all .25s ease;
        &.visible { height: 312px; visibility: visible; }
        ul { list-style: none; margin: 0; padding: 0; }
        li { border-top: 1px solid $primary-colour; margin: 0;
            a { padding: 10px; color: #fff; text-decoration: none; text-transform: uppercase; text-align: left; display: block; opacity: 0; transform: scale(.7,.7); transition: all .25s ease;
                &:hover { background: rgba(0,0,0,.1); padding-left: 15px; }
                svg { width: 15px; height: 15px; display: inline-block; vertical-align: middle; margin-right: 5px; position: relative; top: -1px; border: 1px solid rgba(255,255,255,.4);  }
            }
            &.selected a { background: $primary-colour; }
        }
    }
}

.visible.languages__list li a { transition: background .25s ease 0s, padding .25s ease 0s, transform .3s ease .15s, opacity .3s ease .15s; }
.visible.languages__list li:nth-child(2) a { transition: background .25s ease 0s, padding .25s ease 0s, transform .3s ease .2s, opacity .3s ease .2s; }
.visible.languages__list li:nth-child(3) a { transition: background .25s ease 0s, padding .25s ease 0s, transform .3s ease .25s, opacity .3s ease .25s; }
.visible.languages__list li:nth-child(4) a { transition: background .25s ease 0s, padding .25s ease 0s, transform .3s ease .3s, opacity .3s ease .3s; }
.visible.languages__list li:nth-child(5) a { transition: background .25s ease 0s, padding .25s ease 0s, transform .3s ease .35s, opacity .3s ease .35s; }
.visible.languages__list li:nth-child(6) a { transition: background .25s ease 0s, padding .25s ease 0s, transform .3s ease .4s, opacity .3s ease .4s; }
.visible.languages__list li:nth-child(7) a { transition: background .25s ease 0s, padding .25s ease 0s, transform .3s ease .45s, opacity .3s ease .45s; }
.visible.languages__list li:nth-child(8) a { transition: background .25s ease 0s, padding .25s ease 0s, transform .3s ease .5s, opacity .3s ease .5s; }
.visible.languages__list li a { transform: scale(1,1); opacity: 1; }