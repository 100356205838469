/*! Buttons */

$btn-default-colour: #333;
$btn-default-bg: #fff;
$btn-default-border: #ccc;

$btn-primary-colour: #fff;
$btn-primary-bg: $primary-colour;
$btn-primary-border: #ea0e37;

$btn-primary-focus-colour: #fff;
$btn-primary-focus-bg: #333;
$btn-primary-focus-border: #333;

$btn-secondary-colour: #666;
$btn-secondary-bg: #ddd;
$btn-secondary-border: #ddd;

$btn-secondary-focus-colour: #666;
$btn-secondary-focus-bg: #ccc;
$btn-secondary-focus-border: #ccc;

$btn-tertiary-colour: #fff;
$btn-tertiary-bg: #c30;
$btn-tertiary-border: #c30;

$btn-tertiary-focus-colour: #fff;
$btn-tertiary-focus-bg: darken($btn-tertiary-bg, 10%);
$btn-tertiary-focus-border: darken($btn-tertiary-border, 10%);

$btn-padding: 10px 15px;
$lg-btn-padding: 10px 35px;
$sm-btn-padding: 6px 14px;

$btn-border-radius: 25px;

.btn { display: inline-block; padding: $btn-padding; margin-bottom: 0; font-weight: 400; line-height: 1.1; text-align: center; white-space: nowrap; vertical-align: middle; -ms-touch-action: manipulation; touch-action: manipulation; cursor: pointer; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;background-image: none; border-radius: $btn-border-radius; text-decoration: none; transition: background .2s, border-color .2s, color .2s; text-transform: uppercase; border: none; text-align: center; transition: background .25s ease-in, border-color .25s ease-in; position: relative; overflow: hidden; }
.btn:focus { outline: 0; }
.btn:disabled { opacity: .5; }

.btn-default { color: $btn-default-colour; background-color: $btn-default-bg; border-color: $btn-default-border }

.btn-default.active,
.btn-default.focus,
.btn-default:active,
.btn-default:focus,
.btn-default:hover { color: #333; background-color: #e6e6e6; border-color: #adadad; }

.btn-primary:disabled,
.btn-primary:disabled:hover,
.btn-primary { color: $btn-primary-colour; background-color: $btn-primary-bg; border-color: $btn-primary-border; }
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover { color: $btn-primary-focus-colour;background-color: $btn-primary-focus-bg; border-color: $btn-primary-focus-border }

.btn-secondary { color: $btn-secondary-colour; background-color: $btn-secondary-bg; border-color: $btn-secondary-border; }
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover { color: $btn-secondary-focus-colour;background-color: $btn-secondary-focus-bg; border-color: $btn-secondary-focus-border }

.btn-tertiary { color: $btn-tertiary-colour; background-color: $btn-tertiary-bg; border-color: $btn-tertiary-border; }
.btn-tertiary:active,
.btn-tertiary:focus,
.btn-tertiary:hover { color: $btn-tertiary-focus-colour;background-color: $btn-tertiary-focus-bg; border-color: $btn-tertiary-focus-border }

.btn--lg { font-size: 100%; padding: $lg-btn-padding; }
.btn--sm { padding: $sm-btn-padding; position: relative; top: -1px; }

.btn-outline { color: $primary-colour; border: 1px solid transparent; background: none; }
.btn-outline:active,
.btn-outline:focus,
.btn-outline:hover { }
.on .btn-outline { color: $primary-colour; border-color: $primary-colour; padding: 8px 15px }

.btn--add { border-radius: 0 3px 3px 0; margin: -2px 0 0 -7px; height: 31px; padding: 9px 15px 0; }

/*[required] { color: red; box-shadow: none; }*/


.btn--anim { position: relative; user-select: none; }

.c-ripple { position: absolute; top: 0; left: 0; width: 100%; height: 100%; overflow: hidden; background: transparent; display: block; }
.c-ripple__circle { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 0; width: 0; height: 0; border-radius: 50%; background: rgba(255, 255, 255, .25);
  .c-ripple.is-active & {
    animation: a-ripple .4s ease-in;
  }
}

.btn-plain { padding: 0; border: none; background: none;
	&:hover { color: $primary-colour; }
}

.btn-outline { color: #666; border: 1px solid #ccc; border-radius: 5px;
 &:hover { color: #FFF; border: 1px solid #333; background-color: #333}
}

.btn-input-inline { height: 31px; line-height: 31px; padding: 0 15px; border-radius: 0 5px 5px 0; margin: -2px 0 0 -15px; }
.btn-input-inline:disabled { opacity: 1; background: #ccc; color: #999; }

@keyframes a-ripple {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    width: 200%;
    padding-bottom: 200%;
    opacity: 0;
  }
}

.btn-center {
    width: auto;
    display: inline-block;
    margin: 20px auto;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
}
