.tag-filter-dropdowns,
.pagination-thumb-view-filter,
.selected-tag-filters { max-width: 980px; margin: 0 auto; position: relative; }
.tag-filter-holder { display: inline-block; margin-right: 10px; }
.tag-filter-dropdown { position: absolute; left: -20px; right: -20px; top: 41px; background: linear-gradient(to right, #f96332 0%, #cc0066 100%); padding: 0 20px 5px; z-index: 10; background: #f5f5f5; box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); color: #666;
                       /*                       p { font-size: 1em; }*/
                       select { padding: 5px 40px 5px 8px; margin-left: 5px; width: auto; }
}

.tag-filter-list {  font-size: 1.2em; margin: 0 0 10px; padding: 10px 10px 5px; background: #ededed; text-align: center;
                    button { margin: 0 5px 5px 0; background: #fff; border: 1px solid #ccc; padding: 4px 10px; display: inline-block; border-radius: 3px; margin: 0 5px 5px 0; position: relative;
                             &:disabled { opacity: .2; }
                    }
                    button.on { color: #f96332; }
                    .pagination { text-align: center; margin: 5px 0 0; padding: 10px 0 0; border-top: 1px dotted #ccc;
                                  a,
                                  span { border-color: #ccc; }
                                  li { font-size: 10px; }
                    }
                    &:empty { display: none; }
}
.selected-tag-filters {
    font-size: 1.2em;
    button.tag { color: #f96332; }
}

.tag-filter-empty-message { background: #ededed; text-align: center; padding: 20px; margin: 0 0 10px; font-style: italic;font-size: 1.2em; }
.tag-filter-remove-all { color: #666; background: none; padding: 0; border: none; 
                         &:hover { text-decoration: underline; }
}
.tag-filter-cta-holder { float:right; }
.tag-filter-cta {  color: $btn-secondary-colour;}
.tag-filter-trigger { border: 1px solid #ccc; padding: 6px 8px; border-radius: 5px; background: #fff; transition: border-color 0.2s, box-shadow 0.2s;
                      color: #666; text-decoration: none; font-size: 1.2em; padding-right: 40px; position: relative; display: inline-block;
                      &:after { content: '\f100'; position: absolute; right: 10px; top: 0; font-family: Flaticon; display: block; pointer-events: none; color: #666; font-size: 60%; height: 32px; line-height: 32px; }
}
.tag-filter-trigger.on { border-color: #666;
                         &:after { transform: rotate(180deg); }
}

.tag-filter-count { font-size: 10px; background: #ededed; border-radius: 3px; display: inline-block; padding: 1px 5px; color: #666; text-align: center; width: 25px; margin: 0 -5px 0 5px; }
.tag-filter-label { font-size: 12px; color: #666; margin-right: 10px; }

.tag-filter-option { text-align: center; border-top: 1px solid #ddd; padding: 10px 0 0; }

