/* Star Rating */

.star-rating { padding: 0 20px 15px; margin: -6px -20px 15px; border-bottom: 1px solid #ededed; }

.star-rating__checkbox { position: absolute; overflow: hidden; clip: rect(0 0 0 0); height: 1px; width: 1px; margin: -1px; padding: 0; border: 0; }

.star-rating__star { display: inline-block; padding: 0; vertical-align: middle; line-height: 1; font-size: 3em; color: #ededed; transition: all .2s ease-out; text-shadow: -1px -1px 0 rgba(0,0,0,.25); margin: 0; }

.star-rating__star:hover { cursor: pointer; }

.star-rating__star.active { color: $primary-colour; text-shadow: 1px 1px 0 rgba(0,0,0,.25); margin: -1px 1px 1px -1px; }