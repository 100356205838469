
/**
 * Variables
 */

$calentim-border-color: #ededed;
$calentim-border: 1px solid $calentim-border-color;
$calentim-selected-day: $primary-colour;
$calentim-selected: $primary-colour;
$calentim-disabled-color: #ccc;
$calentim-weekend-color: #b0644c;
$calentim-hover: #fcb198;
$calentim-first-last: #fa825b;
$calentim-header: #666;
$calentim-header-sub: #999;
$calentim-arrow: #999;
$calentim-month-name: #6a6e71;
$calentim-mobile-background: ghostwhite;
$calentim-mobile-buttons: #f03a47;
$calentim-font-family: $font-stack;
$calentim-selected-disabled-background: #fee0d6;
$calentim-selected-disabled-color: white;
$calentim-selected-disabled-transparency: 0.7;
