/*--
IMAGE CROPPER
--*/

.image-cropper-holder { position: relative; }
.cropper-tabs { display: flex; list-style: none; margin: 0; padding: 0;
    li { flex: 1; margin: 0; }
    a { display: block; padding: 20px; text-align: left; text-decoration: none; position: relative; color: #666; background: #ededed; }
    li.on a { background: #fff; }
    li a { border-left: 1px solid #ededed; }
    li:first-child a { border: none; }
}
.image-empty a::after,
.image-full a::after { position: absolute; display: block; right: 20px; top: 50%; height: 20px; width: 20px; line-height: 20px; transform: translateY(-50%); content: ''; font-family: "Flaticon"; font-style: normal; font-weight: normal; text-align: center; }
.image-empty a::after { content: "\f10e"; color: #da3124; font-size: 70%; }
.image-full a::after { content: "\f114"; color: #5cb860; font-size: 70%; }

.set-up-croppers { background: linear-gradient(to right, #f96332 0%, #cc0066 100%); box-shadow: inset 0 0 30px rgba(0,0,0,.1); position: relative; transition: background 3s ease; padding: 0 0 5px; border-radius: 0 0 5px 5px;
    .standard-form-input label { color: #fff; }
}
.bg-white .standard-form-input label { color: #666; }
.image-cropper { padding: 20px 20px 15px; }
.set-cropper-bg { position: absolute; right: 25px; top: 30px; list-style: none; z-index: 4;
    li { float: left; margin: 0 5px; }
    a { display: block; height: 30px; width: 30px; border-radius: 50%; background: linear-gradient(to right, #f96332 0%, #cc0066 100%); text-indent: -9999rem; text-decoration: none; box-shadow: 0 0 5px rgba(0,0,0,.5); position: relative; border: 1px solid #fff; }
    a.bg-white { background: linear-gradient(to right, #fff 0%, #f6f6f6 100%); }
    a.bg-black { background: linear-gradient(to right, #000 0%, #111 100%); }
    .on a::after { position: absolute; left: 50%; top: 50%; width: 4px; height: 4px; transform: translate(-50%,-50%); background: #fff; content: ''; display: block; border-radius: 50%; box-shadow: 0 0 5px rgba(0,0,0,.6); }
}
.bg-white { background: linear-gradient(to right, #fff 0%, #f6f6f6 100%); }
.bg-black { background: linear-gradient(to right, #000 0%, #333 100%); }

.image-upload-dimensions { position: absolute; left: 20px; top: 16px; color: #fff; text-shadow: 0 0 2px rgba(0,0,0,0.7);
    .bg-white & { color: #333; text-shadow: 0 0 2px rgba(255,255,255,0.7);  }
}

.image-upload-pick-from-existing { position: absolute; left: 50%; bottom: 50px; transform: translateX(-50%); z-index: 3;
    .btn { border: 1px solid #fff; border-radius: 5px; width: 335px; font-size: 12px; background: none; transition: all .25s ease;
        &:hover { background: #fff; color: #f96332; }
    }
}

.slim .slim-label {position: absolute!important;top: 50%!important;transform: translateY(-50%)!important;}
