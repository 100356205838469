.page-header-strip { background: #fff; padding: 60px 0 0; margin: 0 0 30px; box-shadow: 0 0 2px rgba(0,0,0,.2); }

.page-header { max-width: 980px; margin: 0 0 0 20px; padding: 0 0 35px; position: relative; 
    .in-page-navigation-panel & { max-width: 800px; }
}
@media screen and (min-width: 1300px) {
.page-header { margin: 0 auto; }
}

.page-title { color: $primary-colour; font-size: 3.2em; font-weight: 300; }
.page-url { color: #666; font-size: 1.4em; font-weight: 300; }
.page-header-cta { position: absolute; margin: 0; right: 0; top: 5px;
    .btn { margin: 0 0 0 20px; }
}
div.page-header-cta { font-size: 1.4em; }

.in-page-navigation-panel {
    .page-title { margin: 0 0 10px; }
    .page-url { margin: 0; }
}