/*! Tags */

.tags-holder { position: absolute; left: 0; right: 0; top: 83px; bottom: 96px; overflow-y: auto; }

.col-selected-tags { padding: 20px; width: 27%; background: #f5f5f5; position: absolute; top: 83px; bottom: 96px; overflow-y: auto; z-index: 10;
                     .tags-list{
                         li{display: block;}
                     }
}
.col-choose-tags { padding: 20px; border-left: 1px solid #ededed; width: 73%; margin: 0 0 0 auto;
                   h2, h3 {
                       .btn--sm { float: right; }
                   }
                   .form-input-border-b { padding-left: 20px; padding-right: 20px; margin-left: -20px; margin-right: -20px; }
}

.tags-selection { display: flex; min-height: 100%; position: relative;}

.selected-tags-heading { font-size: 1.3em; color: $primary-colour; text-transform: uppercase; position: sticky; background: #f5f5f5; padding: 10px 0; top: 0; z-index: 10; margin: 0 0 5px; }
.tags-list { list-style: none; margin: 0 -20px 20px; padding: 0 20px 15px; border-bottom: 1px solid #ededed;
             li { display: inline-block; margin: 0 5px 5px 0;}
             li.child-category{margin: 0 5px 5px 24px;}
}

.tags-list{
    .tag--with-button, .tag--with-delete, .parent-category{
        cursor: default!important;
    }
}

.tag--with-button.margin-right-bottom { margin-right: 10px; margin-bottom: 10px;}

.tags-list-inline{
    display:inline;
    border-bottom: none;
}

.selected-category-wrapper{
    .tags-list:not(:last-of-type){
        padding: 0 20px;
        border: none;
        margin: 0 -20px 10px;
    }
}
.tag { background: #fff; border: 1px solid #ccc; padding: 4px 30px 4px 10px; display: inline-block; border-radius: 3px; position: relative; overflow: hidden; cursor: pointer;
       a { text-decoration: none; color: inherit; }
       button { background: none; border: none; padding: 0; }
}
.tag:not(.tag--with-button){
    padding: 4px 10px 4px 10px;
}
.parent-tag .tag {  }
.tag-selected { color: #219653; }
.draggable-tag:hover { border-color: #999; cursor: move; }
.remove-tag,
.delete-tag button { position: absolute; right: 0; top: 0; border-left: 1px solid #ccc; font-size: 6px; text-align: center; line-height: 10px; bottom: 0; width: 20px; color: #999; background: #ededed;
                     .glyph-icon { position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); }
}
.add-tag { position: absolute; right: 0; top: 0; border-left: 1px solid #ccc; font-size: 6px; text-align: center; line-height: 10px; bottom: 0; width: 20px; color: #999; background: #ededed;
           .glyph-icon { position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); }
}
.more-tag { position: absolute; right: 0; top: 0; border-left: 1px solid #ccc; font-size: 6px; text-align: center; line-height: 10px; bottom: 0; width: 20px; color: #999; background: #ededed;
            .glyph-icon { position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); }
}
.remove-tag:hover { color: #900; }

.select-tag-heading { font-size: 1.3em; font-weight: 300; padding: 10px 20px; margin: 0 -20px 5px; color: $primary-colour; text-transform: uppercase; top: 0;}

.tag-edit-btn { border: none; background: none; font-size: 14px; color: $primary-colour; padding: 4px 10px; margin-left: 5px; float: right; }

.select-tags-list { list-style: none; margin: 0 -20px 15px; padding: 0 20px 15px; border-bottom: 1px solid #ededed;
                    li { display: inline-block; margin: 0 5px 5px 0; }
}

.select-tag-sub-heading { font-size: 1.3em; font-weight: 300; padding: 10px 0 10px 20px; margin: 0 -20px 15px; color: $primary-colour; background: #fff; text-transform: uppercase;
                          .btn--sm,
                          .tag-edit-btn { margin-right: 20px; }
}

.viewing-tag-category { background: #f6f6f6; padding: 20px 20px 15px; margin: 0 0 15px;
                        .select-tags-list { margin-bottom: 0; padding-bottom: 0; border: none; margin: 0; padding: 0; }
                        .select-tag-sub-heading { margin: 0 -40px 15px; }
}

.centre-tag-actions { text-align: center; }

.tag input { border: none; padding: 6px 0; width: 138px; }

.tags-list-in-page {
    padding: 20px;
    margin: 0;
}

.select-tags-edit-cover { position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: #fff; z-index: 20; padding: 20px 200px 40px; overflow-y: auto;
                          h3 { color: #666; text-transform: uppercase; font-size: 1.2em; margin: 0 0 20px;
                               + .btn { position: absolute; top: 16px; right: 200px; font-size: 1.2em; }
                          }
                          label{white-space: nowrap;margin: 10px;}
}

.tag { animation-name: addTag; animation-duration: .3s; animation-timing-function: linear; animation-delay: .2s; animation-iteration-count: 1; animation-fill-mode: both; }
.tag-deleting { animation-name: removeTag; animation-duration: .3s; animation-timing-function: linear;  animation-iteration-count: 1; animation-fill-mode: forwards; }
@keyframes removeTag {
    0%   { transform: scale(1)}
    25%   { transform: scale(1.1)}
    100%   { transform: scale(0)}
}


@keyframes addTag {
    0%   { transform: scale(0)}
    75%   { transform: scale(1.1)}
    100%   { transform: scale(1)}
}

.autocomplete { display: inline-block;
                input { width: 100% !important; }
}

.autocomplete__suggestions { border: 1px solid #ccc; border-radius: 5px; overflow: hidden; box-shadow: 0 2px 2px rgba(0,0,0,.1);
                             div { background: transparent !important; padding: 10px; border-top: 1px solid #ededed; color: #666; cursor: pointer !important; }
                             div:hover { background: #f5f5f5 !important; }
                             div:first-child { border-top: none; }
}
